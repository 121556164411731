import { ReactElement } from 'react';
import { useLocation } from '@remix-run/react';
import { NavigationPrimary } from '~/services/navigation/components/NavigationPrimary';

export interface GlobalAppProps {
  children: ReactElement;
  className?: string;
}

export const GlobalApp = (props: GlobalAppProps) => {
  const { children } = props;

  // Hooks
  const { pathname } = useLocation();

  // Setup
  const isAuth = pathname.startsWith('/auth');

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div className="global-app">
      {!isAuth && <NavigationPrimary />}
      {children}
    </div>
  );
};
