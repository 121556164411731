import type { NavLinkProps } from '@remix-run/react';

export interface Data extends NavLinkProps {}

export const links: NavLinkProps[] = [
  {
    children: 'Shifts',
    prefetch: 'intent',
    to: '/shifts',
  },
  {
    children: 'Partners',
    prefetch: 'intent',
    to: '/partners',
  },
  {
    children: 'Tasks',
    prefetch: 'intent',
    to: '/tasks',
  },
  // {
  //   children: 'Engineering',
  //   prefetch: 'intent',
  //   to: '/engineering',
  // },
  // {
  //   children: 'Docs',
  //   prefetch: 'intent',
  //   to: '/docs',
  // },
];
